import React from "react"
import Layout from "../../components/layoutCornville"
import MeetthePastorCornville from "../../components/meetthePastorCornville"
import SEO from "../../components/seo"
import PopupLive from "../../components/popupLiveCornville"
import PopupPodcast from "../../components/popupPodcast"
import PageHeaders from "../../components/pageHeaders"

const SermonPost = () => (
  <Layout>
    <SEO title="Home" />
    <MeetthePastorCornville />
    <PageHeaders>Connect With Us</PageHeaders>
    <iframe
      src="https://cccares.formstack.com/forms/connect_cornville"
      title="Connect - Cornville"
      width="100%"
      height="1050px"
      frameBorder="0"
    ></iframe>
  </Layout>
)

export default SermonPost
