import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import PageHeaders from "./pageHeaders"
import AndrewProfile from "../images/AndrewPProfile.png"
import ModalVideo from "react-modal-video"

const UnderlineText = styled(Link)`
  font-family: Montserrat;
  font-weight: 400;
  text-transform: uppercase;
  font-style: italic;
  font-size: 0.8rem;
  color: #312e2e;
  text-decoration: none;
  border-bottom: 1px solid #312e2e;
  @media (max-width: 940px) {
    text-align: center;
    margin-top: 1rem;
    font-size: 1.25rem;
    border-bottom: none;
    text-decoration: underline;
  }
`

const MainText = styled.h2`
  font-family: Montserrat;
  font-weight: 900;
  color: #312e2e;
  font-size: 1.8rem;
  margin-bottom: 0.25rem;
  @media (max-width: 940px) {
    text-align: center;
    margin-top: 1rem;
  }
`

const MainButton = styled.button`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  @media (max-width: 940px) {
    margin: 1rem 0;
    width: 100%;
  }
`

const SecondaryButton = styled(Link)`
  color: #fff;
  background: #312e2e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  @media (max-width: 940px) {
    margin-left: 0;
    width: 95%;
    justify-content: center;
  }
`

class MeetThePastorCornville extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    return (
      <>
        <PageHeaders>Meet the Pastor</PageHeaders>
        <div
          css={css`
            background-color: #edf2f7;
            margin-bottom: 4rem;
            display: flex;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            border-radius: 17px;
            flex-direction: row;
            align-items: center;
            height: 250px;
            margin-top: 4rem;
            @media (max-width: 940px) {
              flex-direction: column;
              height: 450px;
              justify-items: center;
            }
          `}
        >
          <img
            css={css`
              width: 180px;
              margin-left: 4rem;
              @media (max-width: 940px) {
                margin-left: 0;
                margin-top: 2rem;
              }
            `}
            src={AndrewProfile}
            alt=""
          />
          <div
            css={css`
              margin-left: 6rem;
              @media (max-width: 940px) {
                margin-left: 0;
                display: flex;
                justify-items: center;
                flex-direction: column;
              }
            `}
          >
            <MainText>Andrew Puett</MainText>
            <div
              css={css`
                display: flex;
                margin-bottom: 0.5rem;
                @media (max-width: 940px) {
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                }
              `}
            >
              <a
                css={css`
                  text-decoration: none;
                `}
                href="mailto:apuett@crosschurchcares.com"
              >
                <MainButton>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    css={css`
                      margin-right: 1rem;
                    `}
                  >
                    <path
                      d="M27.1875 3.75H2.8125C1.25918 3.75 0 5.00918 0 6.5625V23.4375C0 24.9908 1.25918 26.25 2.8125 26.25H27.1875C28.7408 26.25 30 24.9908 30 23.4375V6.5625C30 5.00918 28.7408 3.75 27.1875 3.75ZM27.1875 6.5625V8.95342C25.8737 10.0233 23.7792 11.6869 19.3015 15.1931C18.3148 15.9693 16.3601 17.834 15 17.8123C13.6402 17.8342 11.6848 15.969 10.6985 15.1931C6.22148 11.6874 4.12646 10.0235 2.8125 8.95342V6.5625H27.1875ZM2.8125 23.4375V12.5624C4.15512 13.6318 6.05912 15.1324 8.96121 17.4049C10.2419 18.413 12.4847 20.6385 15 20.6249C17.5029 20.6385 19.7173 18.4453 21.0383 17.4054C23.9403 15.1329 25.8448 13.6319 27.1875 12.5624V23.4375H2.8125Z"
                      fill="white"
                    />
                  </svg>
                  Contact Pastor Andrew
                </MainButton>
              </a>
            </div>
            <UnderlineText to="/cornville/leadership">
              Meet our leadership team
            </UnderlineText>
          </div>
        </div>
      </>
    )
  }
}

export default MeetThePastorCornville
