import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const UnderlineText = styled(Link)`
  font-family: Montserrat;
  font-weight: 400;
  text-transform: uppercase;
  font-style: italic;
  font-size: 0.75rem;
  color: #e5e5e5;
  padding: 1rem;
  text-decoration: none;
`

const Underline = styled.span`
  border-bottom: 1px solid #fff;
`

const MainText = styled.h3`
  font-family: Montserrat;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 1rem;
  padding-left: 1rem;
`

const LandingBar = () => (
  <div
    css={css`
      background: #312e2e;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem calc((100vw - 1050px) / 2);
      height: 135px;
      align-items: center;
      @media (max-width: 1050px) {
        padding: 2.25rem;
      }
      @media (max-width: 640px) {
        flex-direction: column;
        height: 240px;
        padding-left: 3rem;
      }
      @media (max-width: 420px) {
        flex-direction: column;
        height: 240px;
        padding-left: 2rem;
      }
    `}
  >
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        @media (max-width: 640px) {
          text-align: left;
          width: 100%;
        }
      `}
    >
      <div>
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 0.59375C8.83203 0.59375 0.59375 8.83203 0.59375 19C0.59375 29.168 8.83203 37.4062 19 37.4062C29.168 37.4062 37.4062 29.168 37.4062 19C37.4062 8.83203 29.168 0.59375 19 0.59375ZM19 33.8438C10.7988 33.8438 4.15625 27.2012 4.15625 19C4.15625 10.7988 10.7988 4.15625 19 4.15625C27.2012 4.15625 33.8438 10.7988 33.8438 19C33.8438 27.2012 27.2012 33.8438 19 33.8438ZM23.5867 26.0953L17.2855 21.516C17.0555 21.3453 16.9219 21.0781 16.9219 20.7961V8.60938C16.9219 8.11953 17.3227 7.71875 17.8125 7.71875H20.1875C20.6773 7.71875 21.0781 8.11953 21.0781 8.60938V19.1262L26.0359 22.7332C26.4367 23.0227 26.5184 23.5793 26.2289 23.9801L24.8336 25.9023C24.5441 26.2957 23.9875 26.3848 23.5867 26.0953Z"
            fill="white"
          />
        </svg>
      </div>
      <div>
        <MainText>Sundays at 9:00am and 10:30am</MainText>
        <UnderlineText to="/whattoexpect">
          <Underline>What to expect on the weekend</Underline>
        </UnderlineText>
      </div>
    </div>
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        @media (max-width: 640px) {
          text-align: left;
          width: 100%;
        }
      `}
    >
      <div>
        <svg
          width="37"
          height="33"
          viewBox="0 0 37 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.5 0C14.0298 0 10.4062 3.6358 10.4062 8.12109C10.4062 11.7472 15.6961 18.3562 17.7227 20.7552C18.1332 21.2412 18.8674 21.2412 19.2773 20.7552C21.3039 18.3562 26.5938 11.7472 26.5938 8.12109C26.5938 3.6358 22.9702 0 18.5 0ZM18.5 10.8281C17.0097 10.8281 15.8021 9.61641 15.8021 8.12109C15.8021 6.62578 17.0097 5.41406 18.5 5.41406C19.9903 5.41406 21.1979 6.62578 21.1979 8.12109C21.1979 9.61641 19.9903 10.8281 18.5 10.8281ZM1.29243 13.9187C0.910982 14.0717 0.58399 14.336 0.353626 14.6773C0.123262 15.0186 9.13571e-05 15.4213 0 15.8336L0 31.9675C0 32.6971 0.734219 33.1959 1.40934 32.9252L10.2778 28.875V13.8523C9.70993 12.8223 9.2455 11.8194 8.91276 10.8604L1.29243 13.9187ZM18.5 23.1819C17.5962 23.1819 16.7412 22.7835 16.1547 22.0887C14.8919 20.5934 13.5487 18.8906 12.3333 17.1439V28.8744L24.6667 32.9994V17.1445C23.4513 18.8906 22.1088 20.5941 20.8453 22.0894C20.2588 22.7835 19.4038 23.1819 18.5 23.1819ZM35.5907 10.3873L26.7222 14.4375V33L35.7076 29.3938C36.0891 29.2408 36.4161 28.9766 36.6465 28.6353C36.8769 28.294 37 27.8912 37 27.4789V11.345C37 10.6154 36.2658 10.1166 35.5907 10.3873Z"
            fill="white"
          />
        </svg>
      </div>
      <div>
        <MainText>11340 E Circle Drive</MainText>
        <MainText>Cornville, AZ 86325</MainText>
        <UnderlineText to="/churchnearme">
          Not Near Here? <Underline>Find a Church Near You</Underline>
        </UnderlineText>
      </div>
    </div>
  </div>
)

export default LandingBar
